* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif !important;
}

a {
  text-decoration: none !important;
}

main {
  padding-top: 90px;
}

.form-control {
  border: none;
}

.continue_cart {
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 9px;
  padding: 13px;
  background: #ff8502;
  color: white !important;
}

.green_color {
  color: #0fdc0f;
}

.red_color {
  color: #FF3300;
}

.navbar-toggler {
  margin-top: 15px;
}

.main_nav {
  background: white;
}

.main_nav div {
  justify-content: flex-end;
}

.main_nav .logo {
  margin-left: 30px;
}

.main_nav ul {
  justify-content: space-around;
  width: 80%;
  margin: 0 !important;
}

.main_nav ul li {
  list-style: none;
}

.main_nav ul li a {
  color: inherit !important;
}

.main_nav .cart_logo {
  margin-right: 6px;
  margin-top: -4px;
}

.dropdown-menu {
  right: 0;
  left: unset;
}

.basket {
  color: #ff8502;
  padding-top: 10px;
}

.carousel-pic {
  width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.size_cont {
  padding: 5px;
  border: 2px solid #bbb6b6;
  margin: 0 15px;
  width: 60px;
  margin: 0 auto;
  outline: none !important;
}

.size_cont p {
  font-size: 20px;
  text-align: center;
  margin: 0;
}

.size_cont.selected p {
  color: #0EC2BF;
}

#prod_count {
  width: 35px;
  border: none;
  background: transparent;
  margin: 0;
  transition: 0.5s;
  text-align: center;
}

#prod_count:focus {
  border: 1px solid #0EC2BF;
}

.size_cont_outer .size_cont {
  margin-top: 50px;
  margin-bottom: 50px;
}

.choose_cont {
  margin-top: 10px;
}

.dropdown_outer {
  height: auto;
  position: relative;
  top: -200px;
}

.dropdown-cat {
  position: absolute;
  top: 75px;
  background: white;
  width: 50%;
  padding: 6vh 5%;
  z-index: 1;
  background: #f3f3f3;
}

.dropdown-cat .size_cont {
  width: auto !important;
  min-width: 60px;
  margin: 0 10px;
  margin-bottom: 20px;
  color: gray;
}

.dropdown-cat.closed {
  display: none;
}

.category_list {
  margin-top: 30px;
}

.category_list .category_item {
  margin-bottom: 50px;
}

.category_list .category_item > img {
  height: 200px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.category_list .category_item .category_link h6 {
  display: inline-block;
  color: gray;
  font-weight: normal;
  margin-top: 10px;
  letter-spacing: 7px;
}

.category_list .category_item .category_link img {
  margin-top: -3px;
  height: 15px;
}

.services_info {
  background: #0EC2BF;
}

.services_info .info_item {
  color: white;
  margin: 15px 0px;
}

.services_info .info_item p.number {
  font-size: 47px;
  float: left;
  font-style: italic;
  font-weight: 700;
  margin: 0;
  margin-right: 14px;
}

.services_info .info_item p.info {
  margin: 0;
  font-size: 13px;
  margin-top: 15px;
}

.services_info .get_fit {
  color: white;
  width: 95px;
  background: linear-gradient(to right, orange, orangered);
  display: block;
  border-radius: 8px;
  margin-top: 20px;
  padding: 18px 5px;
}

.services_info .get_fit span {
  font-size: 14px;
  margin-left: 5px;
}

.orange_container {
  background: linear-gradient(to right, orange, orangered);
}

.orange_container .icon_section {
  color: white;
  padding: 18px 5px;
  display: flex;
}

.orange_container p {
  font-size: 47px;
  font-weight: 700;
  font-style: italic;
  margin: 0;
  margin-top: -5px;
  margin-left: 29px;
}

.orange_container .links {
  padding: 38px 0px;
  letter-spacing: 2px;
  color: yellow;
}

.orange_container .links a {
  color: yellow;
  font-weight: 400;
}

.tristhetics {
  background-color: #ececec;
}

.tristhetics .logo_thisthetics {
  font-size: 6em;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 7px;
  background: linear-gradient(to right, orange, orangered);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tristhetics .social {
  margin-top: 15px;
}

.tristhetics .social h6 {
  font-weight: bolder;
  font-style: italic;
}

.tristhetics .social a {
  color: black;
}

.collection {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
  background: #0EC2BF;
}

.collection img {
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 5px;
}

.footer_navigation h5 {
  margin: 25px 0;
  letter-spacing: 4px;
}

.footer_navigation .footer_info {
  padding-top: 0%;
  padding-left: 0;
  padding-bottom: 5%;
}

.footer_navigation .footer_service li, .footer_navigation .footer_menu li {
  list-style: none;
}

.footer_navigation .footer_service li a, .footer_navigation .footer_menu li a {
  color: inherit;
}

.footer_end {
  text-align: center;
  color: gray;
}

.payment {
  background: lightgray;
}

.payment > div {
  padding: 2px 10%;
}

.payment .payment_img {
  margin: 10px 3%;
  height: 60px;
}

.payment .paypal {
  height: 30px;
}

.payment .visa {
  height: 70px;
}

.payment .ideal {
  height: 40px;
}

.payment .check_cart {
  margin: 0 auto;
  margin-top: 23px;
  border: 1px solid #ff8502;
  width: 235px;
  padding: 10px;
  background: white;
}

.payment .check_cart img {
  height: 15px;
  margin-top: 4px;
  margin: 4px 12px;
}

.payment .check_cart h5 {
  margin: 0;
  float: left;
  margin-top: 2px;
  color: #aaaaaa !important;
}

.payment .check_cart span {
  color: #ff8502;
}

.PRODUCT_LIST .main {
  margin-top: 38px;
}

.page_heading {
  height: 100%;
  width: auto;
  overflow: hidden;
  position: relative;
}

.PRODUCT_LIST .page_title {
  z-index: 2;
  cursor: pointer;
  bottom: -85px;
}

.PRODUCT_LIST h1 {
  float: left;
}

.PRODUCT_LIST .settings_icon {
  float: right;
  height: 20px;
}

.page_title {
  width: 50%;
  padding: 2%;
  position: absolute;
  bottom: 120px;
  background: white;
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.25098);
  padding-left: 5%;
}

.page_title h1 {
  font-weight: 400;
  font-size: 23px;
  letter-spacing: 4px;
  color: #b0aeae;
  margin: 0;
}

.page_title h1 span {
  color: #0EC2BF;
  font-weight: 500;
}

.page_title img.arrow {
  height: 20px;
  margin-top: -4px;
  margin-right: 10px;
}

.form_container {
  background: #ececec;
  padding: 5vh 10% 5vh 2%;
  margin-bottom: 50px;
}

.contact_heading {
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.contact_rigth {
  justify-content: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.contact_rigth .logo_contact {
  display: inline-block;
  height: 200px;
}

.contact_rigth h3 {
  margin-top: 10px;
  font-weight: lighter;
  color: #ff8502;
}

.contact_rigth p {
  font-weight: bold;
}

.contact_logo_container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.btn_send {
  cursor: pointer;
  padding: 12px 23px;
  color: white;
  border: none;
  font-size: 15px;
  letter-spacing: 3px;
  background: linear-gradient(to right, orange, red);
  border-radius: 8px;
  position: absolute;
  right: 35px;
  bottom: 28px;
}

.table_container {
  margin: 1px;
  margin-top: 0;
}

.shopping_cart {
  margin-top: 50px;
}

.shopping_cart .routes {
  display: flex;
  justify-content: space-between;
  border: 2px solid #ff8502;
}

.shopping_cart .routes .square {
  height: 41px;
  width: 42px;
  transform: rotate(45deg);
  border: 2px solid #ff8502;
  border-left: none;
  border-bottom: none;
  position: absolute;
  right: -21px;
  bottom: 8px;
  z-index: 2;
  background: white;
}

.shopping_cart .routes .square.last {
  display: none;
}

.shopping_cart .routes .route_item {
  border-right: none;
  border-left: none;
  padding: 15px 60px 15px 78px;
  height: 56px;
  position: relative;
}

.shopping_cart .routes .route_item span {
  text-align: center;
  color: #ff8502;
}

.shopping_cart .routes .route_item_active, .shopping_cart .routes .route_item_active .square {
  background: #ff8502;
}

.shopping_cart .routes .route_item_active span, .shopping_cart .routes .route_item_active .square span {
  color: white;
}

.shopping_cart .product_status {
  color: green;
}

.shopping_cart .product_item .title {
  margin-bottom: 40px;
  margin-top: 10px;
}

.shopping_cart .product_item .product_item_inner {
  margin-right: -15px;
}

.shopping_cart .product_item .product_credentials {
  margin-right: 0;
  padding: 4vh 5%;
}

.shopping_cart .product_item .count {
  text-align: center;
  margin-top: 5px;
}

.shopping_cart .product_item .count #count_plus, .shopping_cart .product_item .count #count_minus {
  color: #09dc98;
  font-size: 20px;
  padding: 2px 7px;
  margin: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none !important;
  transition: 0.5s;
}

.shopping_cart .product_item .count #count_minus:hover {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  background: #e6e5e5;
}

.shopping_cart .product_item .count #count_plus:hover {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background: #e6e5e5;
}

.shopping_cart .product_item .price {
  text-align: center;
  margin: 0;
  margin-top: 11px;
}

.shopping_cart .product_item .price p {
  font-size: 17px;
}

.shopping_cart .product_item img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  margin: 0 15px;
  width: 100%;
}

.shopping_cart .product_item:nth-child(even) {
  background: #eeeeee;
}

.shopping_cart .taxes_adds {
  color: white !important;
  background: #5c5c5c !important;
}

.shopping_cart .taxes_adds .title {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.shopping_cart .taxes_adds .price p, .shopping_cart .taxes_adds .price {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 10px !important;
}

.shopping_cart .total_price {
  background: #ff8502 !important;
}

.shopping_cart .cancel_btn {
  text-align: center;
  cursor: pointer;
  display: block;
  border: none;
  font-size: 15px;
  color: rgba(67, 67, 67, 0.85098);
  padding: 5px 10px;
  background: transparent;
  border-bottom: 2px solid rgba(67, 67, 67, 0.85098);
}

.billing_form {
  margin-bottom: 100px;
}

.billing_form label {
  font-weight: bold;
}

.billing_form h6 {
  font-style: italic;
  margin-top: 30px;
  margin-bottom: 30px;
}

.billing_form .col-md-6:first-child {
  background: #eeeeee;
}

.billing_form .col-md-6 {
  padding-bottom: 60px;
}

.billing_form .continue_cart {
  width: 50%;
  float: right;
  margin-right: 50px;
}

.product_list {
  margin-top: 50px;
}

.product_list > a > h5 {
  transform: rotate(90deg);
  position: absolute;
  right: -70px;
  top: 56px;
  color: black;
  letter-spacing: 4px;
}

.product_list .product_item {
  margin-bottom: 20px;
}

.product_list .product_item .area_img img {
  min-height: 250px;
  width: 100%;
}

.product_list .product_item .area_text p {
  color: gray;
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 2px;
}

.product_list .product_item .area_text span {
  color: #ff8502;
  margin-left: 10px;
}

.pagination_container {
  background: #5c5c5c;
  padding: 25px 0;
}

.pagination_container h4 {
  text-align: center;
  color: #aaaaaa;
  margin: 0;
}

.pagination_container h4 span {
  color: #0EC2BF;
}

.pagination_container .pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_container .pagination li {
  padding: 0 8px;
  color: #aaaaaa;
}

.pagination_container .pagination li a {
  color: #aaaaaa;
}

.pagination_container .pagination li.active a {
  color: #0EC2BF;
}

.product_details {
  margin-top: 50px;
  margin-bottom: 50px;
}

.product_details > a > h5 {
  transform: rotate(90deg);
  position: absolute;
  right: -70px;
  top: 56px;
  color: black;
  letter-spacing: 4px;
}

.product_details .main_pic_cont {
  max-height: 557px;
  width: 100%;
  overflow: hidden;
}

.product_details .main_pic_cont .main_pic {
  height: auto;
  width: 100%;
  min-height: 250px;
}

.product_details .product_title {
  border-bottom: 1px solid #ff8502;
}

.product_details .product_title h6 {
  color: #aaaaaa;
  letter-spacing: 2px;
  padding: 25px 0;
  margin: 0;
}

.product_details .product_title h6 span {
  color: #ff8502;
}

.product_details .product_title p {
  margin: 0;
  font-weight: 500;
  color: #aaaaaa;
}

.product_details .product_title p:first-child {
  margin-top: 10px;
}

.product_details .product_info h5 {
  font-size: 18px;
  padding: 15px 0;
  margin: 0;
}

.product_details .product_info .product_details_btn {
  border: 0;
  width: 100%;
  border-radius: 8px;
  padding: 5px 35px;
  background: #eeeeee;
  color: gray;
  background: #eeeeee;
}

.product_details .product_info .product_details_btn h5 {
  padding: 0;
  color: #aaaaaa;
}

.product_details .product_info .product_count {
  border: 0;
  background: #eeeeee;
  border-radius: 8px;
  padding: 5px 15px;
  margin-top: 11px;
  width: 60px;
  text-align: center;
}

.product_details .product_info .product_count h5 {
  padding: 0;
  color: black;
}

.product_details .product_item {
  margin-bottom: 20px;
}

.product_details .product_item .area_img img {
  height: 250px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product_details .product_item .area_text p {
  color: gray;
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 2px;
}

.product_details .product_item .area_text span {
  color: #ff8502;
  margin-left: 10px;
}

.shipping_info {
  margin-top: 50px;
}

.shipping_table {
  border: 2px solid #0EC2BF;
}

.shipping_table .shipping_table_header {
  background: #0EC2BF;
  color: white;
  padding: 10px 10px;
}

.shipping_table .shipping_table_header p {
  margin: 0;
}

.shipping_table .shipping_table_content {
  border-bottom: 2px solid #0EC2BF;
  padding-top: 10px;
  color: #0EC2BF;
  font-weight: 500;
}

.shipping_table .shipping_table_section p {
  padding-top: 10px;
  font-weight: 500;
  font-size: 14px;
}

.shipping_table .shipping_table_section:nth-child(odd) {
  background: #eeeeee;
}

.shipping_table .shipping_table_content :first-child p, .shipping_table .shipping_table_section :first-child p {
  padding-left: 30px;
}

.shipping_table .shipping_table_content :nth-child(2), .shipping_table .shipping_table_content :nth-child(3),
.shipping_table .shipping_table_section :nth-child(2), .shipping_table .shipping_table_section :nth-child(3) {
  text-align: center;
}

.size_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.size_container h5 {
  margin-bottom: 20px;
}

.size_container p {
  font-size: 14px;
  margin: 2px 0;
}

.size_container p span {
  font-weight: 500;
}

.size_container p.main_title {
  font-size: 16px;
  font-weight: 500;
}

.size_container .table_header .custom-select {
  height: 33px;
}

.size_container .table_header .select_label {
  font-weight: 500;
  font-size: 15px;
  margin-right: 10px;
}

.size_container .table_header .col-md-5 {
  margin-top: 12px;
}

.size_container .table_header .col-md-7 {
  padding: 15px 0;
  color: white;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 500;
  background: #0EC2BF;
}

.size_container .table_header .col-md-7 p {
  margin-left: 60px;
}

.size_container .table {
  border: 2px solid #0EC2BF;
}

.size_container .table thead tr th {
  border-bottom: 2px solid #0EC2BF;
}

.size_container .table thead th {
  text-align: center;
  color: #0EC2BF;
  font-weight: 500;
}

.size_container .table tbody tr {
  font-weight: 500;
  text-align: center;
}

.size_container .table .row_title {
  font-style: italic;
}

.service_container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.service_container .square_service {
  height: 90px;
  width: 90px;
  background: linear-gradient(to right, orange, orangered);
  position: relative;
  float: left;
}

.service_container .square_service span {
  position: absolute;
  font-size: 60px;
  color: white;
  font-weight: bolder;
  bottom: -10px;
  right: 10px;
  font-weight: 700;
  font-style: italic;
}

.service_container .section_1 {
  margin-top: 30px;
}

.service_container .section_1 h5 {
  margin-bottom: 20px;
}

.service_container .section_2 {
  margin-top: 30px;
}

.service_container .section_2 h5 {
  margin-bottom: 50px;
}

.service_container .img_cont {
  background: repeating-linear-gradient(45deg, #fff, #fff 9px, #ff0000 7px, #ff0000 12px);
  margin-top: 150px;
  height: 260px;
  position: relative;
}

.service_container .img_cont img {
  margin-left: -60px;
  margin-top: -80px;
  border: 5px solid white;
}

.service_container .img_cont_2 {
  background: repeating-linear-gradient(45deg, #fff, #fff 9px, #0EC2BF 7px, #0EC2BF 12px);
  height: 100px;
  width: 50%;
  position: relative;
}

.service_container .img_cont_2 img {
  margin-left: 50px;
  margin-top: 40px;
  max-width: 200%;
}

.service_container .img_cont_3 img {
  margin-top: -150px;
  border: 5px solid white;
}

.shipping_table2 .col-md-4 {
  padding: 10px 0;
  color: white;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  background: #0EC2BF;
}

.shipping_table2 .col-md-4 p {
  margin: 0;
  text-align: center;
}

.shipping_table2 .table_title {
  margin: 0;
  margin-top: 11px;
}

.shipping_table tr th {
  border-bottom: 2px solid #0EC2BF;
  color: #0EC2BF;
}

@media screen and (max-width: 992px) {
  .shopping_cart .routes {
    flex-direction: column;
    width: 100%;
  }
  .shopping_cart .routes .square {
    display: none;
  }
  .basics {
    margin-top: 100px;
  }
  .tristhetics {
    background-color: #ececec;
  }
  .tristhetics .logo_thisthetics {
    font-size: 4em;
    margin-top: 14px;
  }
  .tristhetics .social {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .tristhetics .social h6 {
    font-weight: bolder;
    font-style: italic;
  }
}

@media screen and (max-width: 768px) {
  .collection img {
    width: 100%;
    padding: 0 5px;
  }
  .service_container .img_cont {
    height: auto;
  }
  .service_container .img_cont_2 {
    width: 100%;
  }
  .service_container .img_cont_2 {
    height: auto;
  }
  .service_container .img_cont img {
    margin: 0 !important;
    background: none;
  }
  .service_container .img_cont_3 img {
    margin: 0 !important;
    background: none;
  }
  .service_container .img_cont_2 img {
    margin: 0 !important;
    background: none;
    max-width: 100%;
  }
  .shopping_cart .product_item img {
    margin: 0;
  }
  .product_details > a > h5 {
    transform: none;
    position: static;
  }
  .product_list > a > h5 {
    transform: none;
    position: static;
  }
  .order_cont {
    margin-bottom: 20px;
  }
  .payment .check_cart {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .PRODUCT_LIST .page_title {
    width: 80%;
  }
  .page_heading .page_title {
    width: 80%;
  }
  .dropdown-cat {
    width: 80%;
  }
  .product_credentials .title {
    text-align: center;
  }
  .product_credentials .product_status {
    text-align: center;
  }
  .size_container .table_header .col-md-7 p {
    margin-left: 10px;
  }
  .tristhetics {
    background-color: #ececec;
  }
  .tristhetics .logo_thisthetics {
    font-size: 2em;
  }
  .tristhetics .social {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .tristhetics .social h6 {
    font-weight: bolder;
    font-style: italic;
  }
  .tristhetics .social img {
    height: 45px;
  }
  .shopping_cart .product_item .size_cont {
    margin: 0 auto;
  }
  .shopping_cart .product_item .product_credentials {
    margin: 0 auto;
  }
}

/*# sourceMappingURL=style.css.map */
